<template>
  <div class="page_box">
    <div class="active_header">
      <img :src="bannder" />
    </div>
    <div class="active_name">
      <span>{{ title }}</span>
    </div>
    <div class="box">
      <template v-if="activitys_data.length != 0">
        <activitysItemH
          v-for="(item, i) in activitys_data"
          :key="i"
          :class="{ is_left: (i + 1) % 2 == 1 }"
          :default_img="default_img"
          :img_important="img_important"
          :isHorizontal="isHorizontal"
          :item="item"
        ></activitysItemH>
      </template>
      <div class="loading_all_center">
        <isAllDataTip
          v-text="'已加载全部'"
          v-if="total != 0 && total == activitys_data.length"
        ></isAllDataTip>
        <isLoadingTip v-if="loading"></isLoadingTip>
      </div>
      <noDataImg v-if="activitys_data.length == 0 && !loading" />
    </div>
  </div>
</template>
<script>
import noDataImg from "@/baseComponents/noDataImg";
import isLoadingTip from "@/baseComponents/isLoadingTip";
import isAllDataTip from "@/baseComponents/isAllDataTip";
import activitysItemH from "~dls/components/index/activitys/activitysItemH";
export default {
  components: {
    noDataImg,
    isLoadingTip,
    isAllDataTip,
    activitysItemH,
  },
  data() {
    return {
      is_all: false,
      bannder:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/Banner_tree.png",
      title: "",
      default_img: "",
      default_xhdt:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffaApplent/home/active.png",
      default_hyxx:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/xhhz.jpg",
      default_zcfg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/Policies_regulations.png",
      default_hdtz:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/InformationIndustry.png",
      default_hyzf:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/hyzf.jpg",
      default_djgz:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/logo_2.png",
      img_important:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/important.png",
      loading: false,
      total: 6,
      currentPage: 1,
      content_type: "",
      isHorizontal: false,
      activitys_data: [],
    };
  },
  mounted() {
    this.createdFunc();
    if(this.$route.query.content_type == 13 || this.$route.query.content_type == 47){
      this.isHorizontal = true;
    }
    this.getList(1);
    window.addEventListener("scroll", this.scrollEvent, false);
  },
  watch: {
    $route(nv) {
      this.activitys_data = [];
      if(nv.query && (nv.query.content_type == 13 || nv.query.content_type == 47)){
        this.isHorizontal = true;
      }else{
        this.isHorizontal = false;
      }
      this.createdFunc();
      this.currentPage = 1;
      this.getList(1);
      window.addEventListener("scroll", this.scrollEvent, false);
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollEvent, false);
  },
  methods: {
      createdFunc() {
      switch (this.$route.query.content_type * 1) {
        
        case 3:
          this.title = "行业动态";
          this.default_img = this.default_hdtz;
          break;
        case 1:
          this.title = "协会活动";
          this.default_img = this.default_xhdt;
          break;
        case 7:
          this.title = "协会合作";
          this.default_img = this.default_hyxx;
          break;
        case 4:
          this.title = "政策法规";
          this.default_img = this.default_zcfg;
          break;
        case 8:
          this.title = "会员走访";
          this.default_img = this.default_hyzf;
          break;
        case 13:
          this.title = "党建工作";
          if(this.$route.query.sub_type == 2){
            this.title = "工作动态";
          }else if(this.$route.query.sub_type == 3){
            this.title = "学习教育";
          }
          this.default_img = this.default_djgz;
          break;
        case 47:
          this.title = "企业评估";
          this.default_img = this.default_hyzf;
          break;
        default:
          this.$router.push({
            name: "index",
          });
          break;
      }
      document.title = this.title;
    },
    scrollEvent() {
      if (
        document.documentElement.scrollTop +
          document.documentElement.clientHeight >=
        document.body.scrollHeight - 354
      ) {
        if (this.total != this.activitys_data.length) {
          if (!this.is_all) {
            this.currentPage++;
            this.getList(2);
            // 防止多次出发 请求事件
            window.addEventListener("scroll", this.scrollEvent, false);
          }
        }
      }
    },
    async getList(type) {
      //type 1 条件查询  type 2 触底出发
      // 防止多次出发 请求事件
      window.removeEventListener("scroll", this.scrollEvent, false);
      this.loading = true;
      let params = {};
      params.content_type = this.$route.query.content_type;
      if(this.$route.query.sub_type){
        params.sub_type = this.$route.query.sub_type
      }
      params.start = this.currentPage - 1;
      params.limit = 6;
      params.source = this.PJSource;
      params.language = this.LOCALE === "en" ? 1 : 2;
      let res = await this.$store.dispatch(
        "API_index/getStaticContent",
        params
      );
      if (res.success) {
        this.loading = false;
        this.total = res.total;
        if (type == 2) {
          if (res.data.length == 0) {
            this.is_all = true;
          }
          this.activitys_data.push(...res.data);
        } else if (type == 1) {
          this.activitys_data = res.data;
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.page_box {
  background: #fafafa;
}
.active_header {
  position: relative;
  padding-top: 14.6%;
  overflow: hidden;
  img {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.active_name {
  padding: 24px;
  text-align: center;
  span {
    width: 84px;
    height: 29px;
    font-size: 21px;
    font-weight: 800;
    color: #1f292e;
    line-height: 29px;
  }
}
.box {
  width: 1200px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  min-height: 300px;
  position: relative;

  .is_left {
    margin-right: 24px;
  }
  .loading_all_center {
    padding: 15px;
    width: 100%;
    text-align: center;
  }
}
</style>